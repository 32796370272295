body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@layer utilities{
  input[type="range"]::-webkit-slider-thumb {
    background-color: green;
  }
  input[type="range"]::-moz-range-thumb {
    background-color: green;
  }
  input[type="range"]::-ms-thumb {
    background-color: green;
  }
  .notifier {
    position: fixed;
    z-index: 111111111;
    top: 20px;
    right: 20px;
    padding: 10px;
    @apply text-primary;
    @apply font-semibold;
    background-color: #f1f1f1;
    border: 1px solid #ccc;
    border-radius: 4px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  
  .notifier.show {
    opacity: 1;
  }
  
  /* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
  .nav{
    @apply
    relative mx-3;
    cursor: pointer;
    transition: all;
  }
  .nav::after{
    @apply content-[''] bg-secondary h-[3px] w-[0%] left-0 -bottom-[5px] absolute duration-200
  }
  .nav:hover::after{
    @apply w-[100%]
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.load {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: 2em;
}

.span {
  width: 0.3em;
  height: 1em;
  background-color: #04ab05;
}

.span:nth-of-type(1) {
  animation: grow 1s -0.45s ease-in-out infinite;
}

.span:nth-of-type(2) {
  animation: grow 1s -0.3s ease-in-out infinite;
}

.span:nth-of-type(3) {
  animation: grow 1s -0.15s ease-in-out infinite;
}

.span:nth-of-type(4) {
  animation: grow 1s ease-in-out infinite;
}

@keyframes grow {
  0%,
  100% {
    transform: scaleY(1);
  }

  50% {
    transform: scaleY(2);
  }
}
